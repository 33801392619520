import * as bootstrap from "bootstrap";

export const initBootstrap = function (config) {
  // Enabling tooltips
  if (config.offcanvas) {
    const offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))

    offcanvasElementList.map(function (offcanvasEl) {
      return new bootstrap.Offcanvas(offcanvasEl)
    })
  }

  // Enabling popovers
  if (config.popover) {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {})
    })
  }
}
