// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";
import './accommodations.js';

// Loading bootstrap with optional features
initBootstrap({
  offcanvas: true
});

document.addEventListener("DOMContentLoaded", function(event) {
  const links = document.querySelectorAll('.nav-item');
  const nav = document.getElementById('navbarScroll');
  const navBtn = document.querySelector('.navbar-toggler');
  links.forEach((link) => {
    link.addEventListener('click', () => {
      nav.classList.remove('show');
      navBtn.classList.add('collapsed');
    });
  });
});