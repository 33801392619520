import accommodations from './accommodationsList.json';

let accommodationsDiv = document.querySelector('#accommodation-list .hotels');

accommodations.forEach((hotel) => {
    let card = document.createElement('div');
    card.classList.add('card', 'd-flex', 'flex-column');
    card.innerHTML = createCard(hotel);
    accommodationsDiv.append(card);
});

function createCard(hotel) {
    const output = `
        <img src="${hotel.thumbnail}" alt="${hotel.name}" class="img-fluid thumbnail">
        <div class="card-body">
        <h5 class="card-title">${hotel.name}</h5>
        <div class="card-text">
            <p class="phone">${hotel.phone}</p>
            <p class="address">${hotel.address}</p>
        </div>
        <a href="${hotel.website}" class="btn btn-outline-primary" target="_blank">Go to website</a>
        </div>`;

    return output.trim();
}